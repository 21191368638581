<template>
    <div class="coming-soon-title fadable">
        Coming soon...
    </div>
</template>
  
<script>
    export default {
        name: 'ComingSoonComponent'
    }
</script>
  
<style scoped>

.coming-soon-title {
    text-align: center;
    font-size: 32px;
    margin-bottom: 72px;
}

</style>